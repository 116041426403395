import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { getDomainByCulture } from '../../utils/getHreflangsForHelmet'
import { getFromConfig, getLocalizedUrl } from '../../utils/localeURL'
import PuxStructuredData from '../../utils/PuxStructuredData'
import { WidgetData } from '../builders/WidgetBuilder'

const localizedHomepageUrl = `/${getFromConfig(
  process.env.LOCALE ?? `en`,
  `urlPrefix`,
  process.env.PROJECT ?? `SalmonSoftwareGatsby`
)}`

const activeIsoCode = getFromConfig(
  process.env.LOCALE ?? `en`,
  `isoCode`,
  process.env.PROJECT ?? `SalmonSoftwareGatsby`
)

export interface BreadcrumbsType {
  items: [BreadcrumbItem]
  showOverWidget?: boolean
  showAll?: boolean
}

export interface BreadcrumbItem {
  displayText: string
  path: string
}

export interface BreadcrumbJson {
  '@context': string
  '@type': string
  itemListElement: BreadcrumbJsonItem[]
}

interface BreadcrumbJsonItem {
  '@type': string
  position: number
  name: string
  item: string
}

export function breadcrumbsOnTopImage(widgetDataArray: [WidgetData]): boolean {
  const foundTopImage: boolean[] = []
  widgetDataArray?.map((widgetData, index) => {
    if ((widgetData?.contentType === `SalmonSoftwareTopImageContainer` || widgetData?.contentType === `SalmonSoftwareVideoBanner` || widgetData?.contentType === `SalmonSoftwareHeroVideo`) && index === 0) {
      return foundTopImage.push(true)
    }
    if (widgetData?.contentType === `PuxSection` && index === 0) {
      widgetData.flow?.widgets.map((w, i) => {
        if ((w.contentType === `SalmonSoftwareTopImageContainer` || w.contentType === `SalmonSoftwareVideoBanner` || w.contentType === `SalmonSoftwareHeroVideo`) && i === 0) {
          return foundTopImage.push(true)
        }
        return foundTopImage.push(false)
      })
    }
    return foundTopImage.push(false)
  })

  return foundTopImage.includes(true)
}

function getBreadcrumbsJson(items: [BreadcrumbItem]) {
  const json: BreadcrumbJson = {
    '@context': `https://schema.org`,
    '@type': `BreadcrumbList`,
    itemListElement: [],
  }

  items?.map((item, index) => {
    const localizedPath = getLocalizedUrl(item.path)
    const domain = getDomainByCulture(activeIsoCode)
    const finalPath = localizedPath.startsWith(`http`)
      ? localizedPath
      : domain + localizedPath

    const jsonItem: BreadcrumbJsonItem = {
      '@type': `ListItem`,
      position: index + 1,
      name: item.displayText,
      item: finalPath,
    }
    json.itemListElement.push(jsonItem)
  })

  return json
}

const Breadcrumbs: FunctionComponent<BreadcrumbsType> = (props) => {
  const { items, showAll, showOverWidget } = props

  const home = (
    <span className='BreadcrumbsItem BreadcrumbsItem--home'>
      <Link
        to={`${localizedHomepageUrl}`}
        key={`home`}
      >
        Homepage
      </Link>
    </span>
  )

  const buildBreadcrumbs = (items: [BreadcrumbItem]) => (
    <div className={`Breadcrumbs${showOverWidget ? " Breadcrumbs--overWidget" : ""}`}>
      <PuxStructuredData structuredData={getBreadcrumbsJson(items)} />
      <div className='PuxContainer'>
        <div className='Breadcrumbs-grid'>
          {home}
          {items?.map((item, index) => {
            return (
              <span className='BreadcrumbsItem' key={index}>
                <Link
                  to={`${getLocalizedUrl(item.path)}`}
                  key={index}
                >
                  {item.displayText}
                </Link>
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )

  if (items && items.length > 0) {
    return buildBreadcrumbs(items)
  } else if (showAll) {
    return buildBreadcrumbs(items)
  } else {
    return null
  }
}

export default Breadcrumbs
